<template>
  <div style="width:94%; height:100%;margin-left:3%">
    <el-table :data="tableData"  style="width: 100%; height:80%"
              :header-cell-style="{backgroundColor:'#4E6F7E',color:'#FFFFFF',textAlign:'center'}"
              :cell-style="{backgroundColor:'#204863',color:'#FFFFFF',textAlign:'center'}">
      <el-table-column prop="createTime" label="监测时间" />
      <el-table-column label="进风风速">
        <el-table-column prop="jWindSpeed" label="风量" />
      </el-table-column>
      <el-table-column label="直风风速">
        <el-table-column prop="zWindSpeed" label="风量" />
      </el-table-column>
      <el-table-column label="分风风速">
        <el-table-column prop="fWindSpeed" label="风量" />
      </el-table-column>
      <el-table-column label="排风风速">
        <el-table-column prop="pWindSpeed" label="风量" />
      </el-table-column>
      <el-table-column label="前左风速粉尘">
        <el-table-column prop="tLWindSpeed" label="风量" />
        <el-table-column prop="tLDust" label="粉尘" />
      </el-table-column>
      <el-table-column label="前右风速粉尘">
        <el-table-column prop="tRWindSpeed" label="风量" />
        <el-table-column prop="tRDust" label="粉尘" />
      </el-table-column>
      <el-table-column label="中左风速粉尘">
        <el-table-column prop="mLWindSpeed" label="风量" />
        <el-table-column prop="mLDust" label="粉尘" />
      </el-table-column>
      <el-table-column label="中右风速粉尘">
        <el-table-column prop="mRWindSpeed" label="风量" />
        <el-table-column prop="mRDust" label="粉尘" />
      </el-table-column>
      <el-table-column label="后左风速粉尘">
        <el-table-column prop="bLWindSpeed" label="风量" />
        <el-table-column prop="bLDust" label="粉尘" />
      </el-table-column>
      <el-table-column label="后右风速粉尘">
        <el-table-column prop="bRWindSpeed" label="风量" />
        <el-table-column prop="bRDust" label="粉尘" />
      </el-table-column>
      <el-table-column label="回风风速粉尘">
        <el-table-column prop="hWindSpeed" label="风量" />
        <el-table-column prop="hDust" label="粉尘" />
      </el-table-column>
    </el-table>
    <div style="float: right;margin-top:20px;">
      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10]"
          :disabled="disabled"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return{
      currentPage:1,
      pageSize:10,
      total:0,
      size: 'small',
      disabled:false,
      background:true,
    }
  },
  methods:{
    handleSizeChange(){

    },
    handleCurrentChange(){

    }
  }
}
</script>
<style>
.cell{
  font-size:11px;
  font-weight: 500;
}
.el-table .cell{
  padding:0 0 !important;
}
.el-scrollbar__wrap--hidden-default{
  background-color: #204863;
}
.el-table--border::before,
.el-table--border::after {
  border-color: #FFFFFF !important;
}
.el-table td,
.el-table th {
  border-color: #FFFFFF !important;
}
.el-pagination>.is-first{
  color:#FFFFFF !important;
}
.el-pagination__goto{
  color:#FFFFFF !important;
}


</style>
