import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import Login from '../views/Login.vue'
import Detail from '../views/Detail.vue'
import Table from '../components/Table.vue'
import Echarts from '../components/Echarts.vue'
import Warning from '../views/Warning.vue'
import Setting from '../views/Setting.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  {
    path: '/table',
    name: 'table',
    component: Table
  },
  {
    path: '/echarts',
    name: 'echarts',
    component: Echarts
  },
  {
    path: '/warning',
    name: 'warning',
    component: Warning
  },{
    path: '/setting',
    name: 'setting',
    component: Setting
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
