<template>
  <div id="home" style="width:100%;height:100%;background-image: url('/static/images/home_notitle.png');background-repeat: no-repeat;background-size: cover;">
    <div style="z-index:10;width:100%;height:104px;position:absolute;top:0;margin:auto;background-image: url('/static/images/tile.png');background-repeat: no-repeat;background-size: cover;"></div>
    <div id="container"></div>
    <div style="position:absolute;padding-left:60px;padding-top:25px;bottom:0;left:27%;width:670px;height:55px;z-index:12;background-image: url('/static/images/buttomB.png');background-repeat: no-repeat;background-size: cover;">
      <div style="float: left;;cursor: pointer" @click="detail">
        <DataLine style="width:120px;height:20px;line-height: 28px;color:#FFFFFF" ></DataLine>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >数据监控</div>
      </div>
      <div style="float: left;;cursor: pointer" @click="setting">
        <Setting style="width:120px;height:20px;line-height: 28px;color:#FFFFFF" ></Setting>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >阈值设置</div>
      </div>
      <div style="float: left;;cursor: pointer" @click="home">
        <House style="width:120px;height:20px;line-height: 28px;color:#FFFFFF" ></House>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >回首页</div>
      </div>
      <div style="float: left;;cursor: pointer" @click="warning">
        <Sunset style="width:120px;height:20px;line-height: 28px;color:#FFFFFF" ></Sunset>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >报警设置</div>
      </div>
      <div style="float: left;cursor: pointer" @click="login">
        <SwitchButton style="width:120px;height:20px;line-height: 28px;color:#FFFFFF" ></SwitchButton>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >退出登录</div>
      </div>
    </div>
    <div id="arrow" style="position:absolute;width:100%;height:100%; top:0;left:0;background-image: url('/static/images/lineList.png');background-repeat: no-repeat;background-size: cover;"></div>
  </div>
</template>

<script>
import * as THREE from 'three'
import PahoMQTT from 'paho-mqtt';
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { OrbitControls } from "three/addons/controls/OrbitControls";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { CSS2DRenderer,CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import {ElLoading} from "element-plus";

import { range, texture, mix, uv, color, positionLocal, timerLocal, SpriteNodeMaterial } from 'three/nodes';
import WebGPURenderer from 'three/addons/renderers/webgpu/WebGPURenderer.js'
import {CSS3DObject, CSS3DRenderer} from "three/addons";

 export default {
    name: 'HomeView',
    data () {
      this.scene = null;// 场景对象
      this.camera = null;// 透视摄像机
      this.container = null;// 透视摄像机
      this.orbitControls = null;// 渲染器
      this.labelRenderer = null;
      this.threeRenderer = null;
      this.render = null;// 渲染器
      this.particleSystem = null;
      return {
        client:null,
        isConnected:false,
        receivedMessages:[],
        nameList : [
          {name:"云雾降尘",x:2,y:12,z:0},
          {name:"除尘风机",x:-2,y:10,z:0},
          {name:"分风直吹风门",x:-10,y:12,z:0},
          {name:"冲水装置",x:-15,y:7,z:0},
        ],
        tempNameList:[
          {name:"云雾降尘",x:-3.5,y:0,z:15},
          {name:"除尘风机",x:-1,y:0,z:15},
          {name:"分风直吹风门",x:1.5,y:0,z:15},
          {name:"冲水装置",x:4,y:0,z:15}
        ],
        titleList:[
          {name:'排风风速',windSpeed:0,dust:0,type:'short',x:-6.5,y:6.3,z:-0.3},
          {name:'回风风速粉尘',windSpeed:0,dust:0,type:'normal',x:-6.5,y:6.3,z:-0.3},
          {name:'进风风速',windSpeed:0,dust:0,type:'normal',x:-8.5,y:5.2,z:3.5},
          {name:'直射风速',windSpeed:0,dust:0,type:'normal',x:-5.1,y:5.2,z:3.5},
          {name:'分风风速',windSpeed:0,dust:0,type:'normal',x:-5.5,y:5,z:-3.7},
          {name:'后左风速粉尘',windSpeed:0,dust:0,type:'normal',x:-2.5,y:5.8,z:-2.2},
          {name:'中左风速粉尘',windSpeed:0,dust:0,type:'normal',x:0.5,y:5.8,z:-2.2},
          {name:'前左风速粉尘',windSpeed:0,dust:0,type:'normal',x:3.4,y:5.8,z:-2.2},
          {name:'后右风速粉尘',windSpeed:0,dust:0,type:'normal',x:-2.5,y:5.8,z:1.8},
          {name:'中右风速粉尘',windSpeed:0,dust:0,type:'normal',x:0.5,y:5.8,z:1.8},
          {name:'前右风速粉尘',windSpeed:0,dust:0,type:'normal',x:3.4,y:5.8,z:1.8},
        ],
        tempTitleList:[
          {name:'排风风速',windSpeed:0,dust:0,only:true, type:'short',x:-10,y:10,z:5},
          {name:'回风风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',x:-15,y:6.5,z:5},
          {name:'进风风速',windSpeed:0,dust:0,only:true,type:'normal',x:-15,y:3,z:5},
          {name:'直射风速',windSpeed:0,dust:0,only:true,type:'normal',x:-15,y:-0.5,z:5},
          {name:'分风风速',windSpeed:0,dust:0,only:false,type:'normal',x:-15,y:10,z:5},
          {name:'中左风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',x:15,y:10,z:5},
          {name:'中右风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',x:15,y:6.5,z:5},
          {name:'前左风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',x:15,y:3,z:5},
          {name:'前右风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',x:15,y:-0.5,z:5},
          {name:'后右风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',x:-5,y:10,z:5},
          {name:'后左风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',x:0,y:10,z:5},
        ],
        particleCount:5000,
      }
    },
    methods: {
      login(){
        this.clear();
        this.$router.push({ name: 'login' });
      },
      warning(){
        this.clear();
        this.$router.push({ name: 'warning' });
      },
      setting(){
        this.clear();
        this.$router.push({ name: 'setting' });
      },
      detail(){
        this.clear();
        this.$router.push({ name: 'detail' });
      },
      home(){
        console.log(1212)
        this.camera.position.set(0,6,30)
        this.camera.lookAt(0, 6, 0)
        setTimeout(() => {
          this.rebackLabel()
        }, 200);

      },
      rebackLabel(){
        document.getElementById("arrow").style.display = 'block';
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type ==='Object3D') {
            for (let k = 0; k < this.tempTitleList.length; k++) {
              if(this.scene.children[i].element.name === this.tempTitleList[k].name){
                this.scene.children[i].element.style.height ='120px'
                this.scene.children[i].position.set(this.tempTitleList[k].x,this.tempTitleList[k].y+2,this.tempTitleList[k].z)
              }
            }
          }
        }
      },
      clear(){
        document.body.removeChild(document.body.lastChild)
        document.body.removeChild(document.body.lastChild)
      },
      connect() {
        // 创建客户端实例
        this.client = new PahoMQTT.Client(
            'ws://mqtt.ilyixy.com:9001/ws', // MQTT服务器地址
            'clientId_' + Math.random().toString(16).substr(2, 8)
        );
        // 设置连接丢失的回调函数
        this.client.onConnectionLost = (responseObject) => {
          if (responseObject.errorCode !== 0) {
            console.log('onConnectionLost:', responseObject.errorMessage);
          }
        };
        // 设置消息到达的回调函数
        this.client.onMessageArrived = (message) => {
          console.log('onMessageArrived:', message.payloadString);
          this.message = message.payloadString;
        };
        // 连接到服务器
        this.client.connect({ userName:'admin',password:'Ly123456', onSuccess: this.onConnect, onFailure: this.onFailure });
      },
      onConnect() {
        console.log('Connected');
        this.client.subscribe('fc-home',(data) =>{
          console.log(data, 'recieved');
        });
        this.client.onMessageArrived = this.onMessageArrived;
      },
      onFailure(error) {
        console.log('Failed to connect:', error.errorMessage);
      },
      onMessageArrived(message){
        let data = JSON.parse(message.payloadString);
        let avgValue = 0;
        for (let i = 0; i < data.length; i++) {
          if(data[i].dust === ''){
            this.changeLabel(data[i].name,data[i].windSpeed,data[i].dust,true,data[i].hasAlert)
          }else{
            this.changeLabel(data[i].name,data[i].windSpeed,data[i].dust,false,data[i].hasAlert)
          }
          if(data[i].name === '中左风速粉尘' || data[i].name === '中右风速粉尘' || data[i].name === '后左风速粉尘' || data[i].name === '后右'
              || data[i].name === '前左风速粉尘' || data[i].name === '前右风速粉尘'){
            avgValue = avgValue + parseInt(data[i].dust);
          }
          if(data[i].name === '进风风速'){
            this.changeArrow(data[i].windSpeed)
          }
        }
        if( avgValue / 6 > 20 ){
          this.particleCount = 20000
        }
      },
      initScene() {
        this.scene = new THREE.Scene();//创建一个Scene场景
      },
      initLight(){
        const light = new THREE.AmbientLight(0xffffff, 0.8);//创建一个灯光
        this.scene.add(light)
        const plusLight = new THREE.DirectionalLight(0xffffff, 1.2);//创建一个环境
        plusLight.position.set(100,100,100);//设置光源的位置
        this.scene.add(plusLight); //将灯光添加到场景中
        const minLight = new THREE.DirectionalLight(0xffffff, 1);//创建一个环境
        minLight.position.set(-100,-100,-100);//设置光源的位置
        this.scene.add(minLight); //将灯光添加到场景中
      },
      initCamera(){
        //创建一个透视相机，视角为45度，宽高比为window窗口的宽高比，0.1为近面，10000为远面
        this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 10000);
        this.camera.position.set(0,6,30);//设置相机位置
        this.camera.lookAt(0,6,0);//设置相机位置
      },
      initName(name){ //基础材质
        const labelDiv = document.createElement('div');
        labelDiv.textContent = name;
        labelDiv.style.backgroundImage = 'url(/static/images/plugin.png)';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.style.height ='45px';
        labelDiv.style.textAlign ='center';
        labelDiv.style.lineHeight = '45px';
        labelDiv.style.width = '150px';
        labelDiv.style.fontSize = '18px'; // 设置文字大小
        labelDiv.style.color = '#efeff3'; // 设置文字颜色
        labelDiv.name = name;
        return new CSS2DObject(labelDiv);// 使用创建的 DIV 元素初始化一个 CSS2DObject 对象
      },
      initTitle(temp){ //基础材质
        const labelDiv = document.createElement('div');
        labelDiv.style.pointerEvents = 'auto'
        if(temp.only){
          labelDiv.innerHTML =
              '<div style="font-size: 14px;">'+temp.name+'</div>' +
              '<div style="margin-top:30px;margin-left:30px;line-height:60px;">风速 <span style="color:aqua;font-size: 16px;">'+temp.windSpeed+'</span>m/s</div>';
        }else{
          labelDiv.innerHTML =
              '<div style="font-size: 14px;">'+temp.name+'</div>' +
              '<div style="margin-top:30px;margin-left:30px;line-height:35px;">风速 <span style="color:aqua;font-size: 16px;">'+temp.windSpeed+'</span>m/s</div>' +
              '<div style="margin-left:30px;line-height:35px;">粉尘 <span style="color:aqua;font-size: 16px;">'+temp.dust+'</span>g/m³</div>';
        }
        if(temp.type === 'normal' && !temp.only ){
          labelDiv.style.backgroundImage = 'url(/static/images/dustBlue.png)';
        }else if(temp.type === 'error' && !temp.only){
          labelDiv.style.backgroundImage = 'url(/static/images/dustRed.png)';
        }else if(temp.type === 'normal' && temp.only){
          labelDiv.style.backgroundImage = 'url(/static/images/blue.png)';
        }else if(temp.type === 'error'&& temp.only){
          labelDiv.style.backgroundImage = 'url(/static/images/red.png)';
        }else if(temp.type === 'short'){
          labelDiv.style.backgroundImage = 'url(/static/images/shortBlue.png)';
        }
        labelDiv.style.height ='120px';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.className = 'titleLabel';
        labelDiv.style.padding = '10px 4px 4px 40px';
        labelDiv.style.width = '140px';
        labelDiv.style.fontSize = '14px'; // 设置文字大小
        labelDiv.style.color = '#efeff3'; // 设置文字颜色
        labelDiv.name = temp.name; // 设置文字颜色
        return new CSS2DObject(labelDiv);// 使用创建的 DIV 元素初始化一个 CSS2DObject 对象
      },
      initArrow(name){
        const labelDiv = document.createElement('div');
        labelDiv.textContent = '风量13m/s';
        labelDiv.style.backgroundImage = 'url(/static/images/backArrow.png)';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.style.padding = '0px 0px 0px 10px';
        labelDiv.style.height ='20px';
        labelDiv.style.width = '45px';
        labelDiv.style.fontSize = '5px'; // 设置文字大小
        labelDiv.style.lineHeight = '20px'; // 设置文字大小
        labelDiv.style.color = '#2DF6F5'; // 设置文字颜色
        labelDiv.name = name; // 设置文字颜色
        return new CSS3DObject(labelDiv);
      },
      changeArrow(windSpeed){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type ==='Object3D') {
            if(this.scene.children[i].element.name === "liuLiang") {
              this.scene.children[i].element.style.backgroundImage = 'url(/static/images/arrow.png)';
              this.scene.children[i].element.textContent = '风量'+windSpeed+'m/s';
            }
          }
        }
      },
      initError(){ //基础材质
        const labelDiv = document.createElement('div');
        labelDiv.style.backgroundImage = 'url(/static/images/error.png)';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.style.padding = '10px 4px 4px 40px';
        labelDiv.style.height ='80px';
        labelDiv.style.width = '60px';
        labelDiv.style.fontSize = '18px'; // 设置文字大小
        labelDiv.style.color = '#efeff3'; // 设置文字颜色
        labelDiv.name = name;
        return new CSS2DObject(labelDiv);// 使用创建的 DIV 元素初始化一个 CSS2DObject 对象
      },
      initErrorTitle(content){ //基础材质
        const labelDiv = document.createElement('div');
        labelDiv.textContent = content;
        labelDiv.style.backgroundImage = 'url(/static/images/errorTitle.png)';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.style.padding = '10px 4px 4px 40px';
        labelDiv.style.height ='100px';
        labelDiv.style.width = '150px';
        labelDiv.style.fontSize = '18px'; // 设置文字大小
        labelDiv.style.color = '#efeff3'; // 设置文字颜色
        labelDiv.name = name;
        return new CSS2DObject(labelDiv);// 使用创建的 DIV 元素初始化一个 CSS2DObject 对象
      },
      initFire(){
        const textureLoader = new THREE.TextureLoader();
        const map = textureLoader.load( '/static/images/smoke1.png' );
        const lifeRange = range( .1, 1 );
        const offsetRange = range( new THREE.Vector3( - 2, 3, - 2 ), new THREE.Vector3( 2, 5, 2 ) );
        const timer = timerLocal( 0.2, 1/*100000*/ );
        const lifeTime = timer.mul( lifeRange ).mod( 1 );
        const scaleRange = range( 0.3, 2 );
        const rotateRange = range( 0.1, 4 );
        const life = lifeTime.div( lifeRange );
        const fakeLightEffect = positionLocal.y.oneMinus().max( 0.2 );
        const textureNode = texture( map, uv().rotateUV( timer.mul( rotateRange ) ) );
        const opacityNode = textureNode.a.mul( life.oneMinus() );
        const smokeColor = mix( color( '#222222' ), color( '#222222' ), positionLocal.y.mul( 3 ).clamp() );
        const smokeNodeMaterial = new SpriteNodeMaterial();
        smokeNodeMaterial.colorNode = mix( color( '#333333' ), smokeColor, life.mul( 2.5 ).min( 1 ) ).mul( fakeLightEffect );
        smokeNodeMaterial.opacityNode = opacityNode;
        smokeNodeMaterial.positionNode = offsetRange.mul( lifeTime );
        smokeNodeMaterial.scaleNode = scaleRange.mul( lifeTime.max( 0.3 ) );
        smokeNodeMaterial.depthWrite = false;
        smokeNodeMaterial.transparent = true;
        const smokeInstancedSprite = new THREE.Mesh( new THREE.PlaneGeometry( 1, 1 ), smokeNodeMaterial );
        smokeInstancedSprite.scale.setScalar( 5 );
        smokeInstancedSprite.isInstancedMesh = true;
        smokeInstancedSprite.count = 2000;
        smokeInstancedSprite.position.set( 10, -6, 0)
        this.scene.add( smokeInstancedSprite );
        const fireNodeMaterial = new SpriteNodeMaterial();
        fireNodeMaterial.colorNode = mix( color( '#222222' ), color( '#222222' ), life );
        fireNodeMaterial.positionNode = range( new THREE.Vector3( - 1, 1, - 1 ), new THREE.Vector3( 1, 2, 1 ) ).mul( lifeTime );
        fireNodeMaterial.scaleNode = smokeNodeMaterial.scaleNode;
        fireNodeMaterial.opacityNode = opacityNode;
        fireNodeMaterial.blending = THREE.AdditiveBlending;
        fireNodeMaterial.transparent = true;
        fireNodeMaterial.depthWrite = false;
        const fireInstancedSprite = new THREE.Mesh( new THREE.PlaneGeometry( 1, 1 ), fireNodeMaterial );
        fireInstancedSprite.scale.setScalar( 5 );
        fireInstancedSprite.isInstancedMesh = true;
        fireInstancedSprite.count = 100;
        fireInstancedSprite.position.y = - 100;
        fireInstancedSprite.renderOrder = 1;
        this.scene.add( fireInstancedSprite );
      },
      changeLabel(name, windSpeed, dust, only, hasAlert){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type ==='Object3D') {
           if(this.scene.children[i].element.name === name) {
             if(hasAlert === '0' && only){
               this.scene.children[i].element.style.backgroundImage = 'url(/static/images/blue.png)';
             }else if(hasAlert === '1' && only){
               this.scene.children[i].element.style.backgroundImage = 'url(/static/images/red.png)';
             }else if(hasAlert === '0' && !only){
               this.scene.children[i].element.style.backgroundImage = 'url(/static/images/dustBlue.png)';
             }else if(hasAlert === '1' && !only){
               this.scene.children[i].element.style.backgroundImage = 'url(/static/images/dustRed.png)';
             }
             if("排风风速" === name){
               this.scene.children[i].element.style.backgroundImage = 'url(/static/images/shortBlue.png)';
             }
             if (only) {
               this.scene.children[i].element.innerHTML =
                   '<div style="font-size: 14px;">' + name + '</div>' +
                   '<div style="margin-top:30px;margin-left:30px;line-height: 60px;">风速 <span style="color:aqua;font-size: 16px;">' + windSpeed + '</span>m/s</div>';
             } else {
               this.scene.children[i].innerHTML =
                   '<div style="font-size: 14px;">' + name + '</div>' +
                   '<div style="margin-top:30px;margin-left:30px;line-height: 35px;">风速 <span style="color:aqua;font-size: 16px;">' + windSpeed + '</span>m/s</div>' +
                   '<div style="margin-left:30px;line-height: 30px;"">粉尘 <span style="color:aqua;font-size: 16px;">' + dust + '</span>g/m³</div>';
             }
           }
          }
        }
      },
      changePosition(){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type ==='Object3D') {
            for (let k = 0; k < this.titleList.length; k++) {
              if(this.scene.children[i].element.name === this.titleList[k].name){
                if(this.titleList[k].name === '排风风速'){
                  this.scene.children[i].element.style.height ='300px'
                }else{
                  this.scene.children[i].element.style.height ='600px'
                }
                this.scene.children[i].position.set(this.titleList[k].x,this.titleList[k].y,this.titleList[k].z)
              }
            }
            if(document.getElementById("arrow")){
              document.getElementById("arrow").style.display = 'none';
            }
          }
        }
      },
      initSprite(){
        const particles = new THREE.BufferGeometry();
        const positions = new Float32Array(this.particleCount * 3); // 每个粒子需要 x, y, z 坐标
        for (let i = 0; i < this.particleCount; i++) {
          positions[i * 3] = (Math.random()) * 30 -15;
          positions[i * 3 + 1] = (Math.random()) * 10 ;
          positions[i * 3 + 2] = (Math.random()) * 10 ;
        }
        particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        const material = new THREE.PointsMaterial({
          color: 0x000000,
          size: 3,
          transparent: true
        });
        this.particleSystem = new THREE.Points(particles, material);
        this.scene.add(this.particleSystem);
      },
      initObj(){
        // const loading = ElLoading.service({
        //   lock: true,
        //   text: '加载模型中',
        //   background: 'rgba(0, 0, 0, 0.7)',
        // })
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/static/gltf/');
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.preload()
        const _this = this
        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        loader.load('/static/models/model.glb', function ( gltf ) {
          const model = gltf.scene
          model.rotateY(Math.PI)
          for (let i = 0; i < _this.tempNameList.length; i++) {
            let label = _this.initName(_this.tempNameList[i].name)
            label.position.set(_this.tempNameList[i].x,_this.tempNameList[i].y+2,_this.tempNameList[i].z);
            _this.scene.add(label);
          }
          for (let i = 0; i < _this.tempTitleList.length; i++) {
            let label = _this.initTitle(_this.tempTitleList[i])
            label.position.set(_this.tempTitleList[i].x,_this.tempTitleList[i].y+2,_this.tempTitleList[i].z);
            _this.scene.add(label);
            // 添加点击事件监听器
            label.element.addEventListener('click', function() {
              alert('You clicked the label!');
            });
          }
          let lLabel = _this.initArrow("liuLiang");
          lLabel.position.set(-12,6,3);
          lLabel.scale.set(0.1,0.1,0.1)
          _this.scene.add(lLabel);
          // let error = _this.initError("有危險");
          // error.position.set(-45,10,10);
          // model.add(error)
          //
          // let errorTitle = _this.initErrorTitle();
          // errorTitle.position.set(-55,10,10);
          // model.add(errorTitle)
          _this.initFire();
          model.position.set(-3,0,0)
          _this.scene.add(model)

          _this.bandClick();
        }, function ( xhr ) { // 加载过程中，输出加载进度信息
          // if(xhr.loaded === 8373480){
          //   loading.close()
          // }
        }, function ( error ) { // 加载失败时，输出错误信息
          console.log( 'An error happened'+error );
        })
      },
      initRenderer(){//初始化渲染器
        this.renderer = new WebGPURenderer( { antialias: true } );
        this.container = document.getElementById("container");//获取容器
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);//设置渲染器的大小
        this.container.appendChild(this.renderer.domElement);//将渲染器添加到容器中
        this.renderer.setClearColor('#FFFFFF', 0);//设置背景颜色

        // 创建 CSS2D 渲染器
        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = '0px';
        document.body.appendChild(this.labelRenderer.domElement);

        // 创建 CSS3D 渲染器
        this.threeRenderer = new CSS3DRenderer();
        this.threeRenderer.setSize(window.innerWidth, window.innerHeight);
        this.threeRenderer.domElement.style.position = 'absolute';
        this.threeRenderer.domElement.style.top = '0px';
        document.body.appendChild(this.threeRenderer.domElement);
      },
      initOrbitControls(){
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);//创建控制器
        this.orbitControls = new OrbitControls(this.camera, this.labelRenderer.domElement);//创建控制器
        this.orbitControls = new OrbitControls(this.camera, this.threeRenderer.domElement);//创建控制器
        this.orbitControls.enablePan = false;
        this.orbitControls.enableZoom = true;
        this.orbitControls.minDistance = 15
        this.orbitControls.maxDistance = 28
        this.orbitControls.minPolarAngle = Math.PI/3;
        this.orbitControls.maxPolarAngle = Math.PI/2;
        this.orbitControls.minAzimuthAngle = -Math.PI;
        this.orbitControls.maxAzimuthAngle = 0;
        this.orbitControls.target = new THREE.Vector3(0,6,0)
        this.camera.position.set(0,6,30);//设置相机位置
        this.camera.lookAt(0,6,0);//设置相机位置
        let _this = this
        this.orbitControls.addEventListener('change', function () {
          _this.changePosition();
        });
      },
      initAnimate() {
        requestAnimationFrame(this.initAnimate);
        if(this.orbitControls){
          this.orbitControls.update();
        }
        this.renderer.render(this.scene, this.camera);
        this.labelRenderer.render(this.scene, this.camera);
        this.threeRenderer.render(this.scene, this.camera);
      },
      bandClick(){
        var elements = document.getElementsByClassName("titleLabel");
        console.log(elements.length)
        for (let i = 0; i < elements.length; i++) {
          console.log("绑定了点击事件")
          elements[i].addEventListener("click", function() {
            console.log("Element clicked: ", this.id);
          });
        }
      }
    },
    mounted() {
      this.initScene();
      this.initLight();
      this.initCamera();
      this.initObj();
      this.initSprite();
      this.initRenderer();
      this.initOrbitControls();
      this.initAnimate();
      this.connect();
    },
   created() {

   }
 }
</script>
<style scoped>
#home{
  width:100%;
  height:100%;
}
#container {
  width:100%;
  height:100%;
}
</style>
