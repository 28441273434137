<template>
  <div ref="chart"  style="width: 100%; height: 100%"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'Echarts',
  props: {
    echartData: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    echartData(newVal, oldVal) {
      console.log(oldVal)
      console.log(newVal)
      this.initChart(newVal);
    }
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  mounted() {
    // this.initChart();
  },
  methods: {
    initChart(newVal) {
      let windSpeedMax = 0;
      let windSpeedMin = 0;
      let dustMax = 0;
      let dustMin = 0;
      let windSpeedList = [];
      let dustList = [];
      let dataList = [];
      let totalWindSpeed = 0
      let totalDust = 0
      for (let i = 0; i < newVal.length; i++) {
        dataList.push(newVal.createTime);
        if(newVal[i].monitorList) {
          for (let j = 0; j < newVal[i].monitorList.length; j++) {
            windSpeedList.push(newVal[i].monitorList[j].windSpeed)
            dustList.push(newVal[i].monitorList[j].dust)
            if(windSpeedMax<newVal[i].monitorList[j].windSpeed){
              windSpeedMax = newVal[i].monitorList[j].windSpeed;
            }
            if(windSpeedMin > newVal[i].monitorList[j].windSpeed){
              windSpeedMax = newVal[i].monitorList[j].windSpeed;
            }
            if(dustMax<newVal[i].monitorList[j].dust){
              dustMax = newVal[i].monitorList[j].dust;
            }
            if(dustMin > newVal[i].monitorList[j].dust){
              dustMin = newVal[i].monitorList[j].dust;
            }
            totalWindSpeed = totalWindSpeed + parseFloat(newVal[i].monitorList[j].windSpeed)
            totalDust = totalDust + parseFloat(newVal[i].monitorList[j].dust)
          }
        }
      }
      if(totalWindSpeed !== 0){
        totalWindSpeed  = (totalWindSpeed/newVal.length).toFixed(2)
      }
      if(totalDust !== 0){
        totalDust = (totalDust/newVal.length).toFixed(2)
      }
      // 基于准备好的dom，初始化echarts实例
      this.chartInstance = echarts.init(this.$refs.chart);
      // 指定图表的配置项和数据
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          textStyle: {
            color: '#ffffff' // 例如，设置为白色
          },
          data: ['风速', '粉尘'],
          orient:'vertical',
          right:'5%',
          top:'40%',
          formatter(name) {
            if (name === '风速'){
              return name+' 最大值:'+windSpeedMax+'  最小值'+windSpeedMin+'  平均 '+totalWindSpeed;
            }else{
              return name+' 最大值:'+dustMax+'  最小值'+dustMin+'  平均 '+totalDust;
            }
          }
        },
        grid: {
          left: '3%',
          right: '20%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
            type: 'category',
            axisLine: {            // 配置Y轴线条样式
              show: true,
              lineStyle: {
                color: '#FFFFFF'     // 线条颜色，可以根据需要调整
              }
            },
            axisLabel: {        // 配置Y轴刻度标签
              show: true,
              fontSize: 12,    // 字体大小，可以根据需要调整
              color: '#FFFFFF'    // 字体颜色，可以根据需要调整
            },
            axisTick: {         // 配置Y轴刻度
              show: true,
              lineStyle: {
                color: '#333' // 刻度线颜色，可以根据需要调整
              }
            },
            boundaryGap: false,
            data: dataList
        }],
        yAxis: [{
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {            // 配置Y轴线条样式
              show: true,
              lineStyle: {
                color: '#FFFFFF'     // 线条颜色，可以根据需要调整
              }
            },
            axisLabel: {        // 配置Y轴刻度标签
              show: true,
              fontSize: 12,    // 字体大小，可以根据需要调整
              color: '#FFFFFF'    // 字体颜色，可以根据需要调整
            },
            axisTick: {         // 配置Y轴刻度
              show: true,
              lineStyle: {
                color: '#333' // 刻度线颜色，可以根据需要调整
              }
            }
        }],
        series: [
          {
            name: '风速',
            type: 'line',
            stack: 'Total',
            symbol: 'circle',  // 数据点形状为三角形
            symbolSize: 10,       // 数据点大小为10
            itemStyle: {
              color: '#F6A92D'      // 数据点颜色为红色
            },
            areaStyle: {
              color: '#F6A92D'
            },
            data: windSpeedList
          },
          {
            name: '粉尘',
            type: 'line',
            stack: 'Total',
            areaStyle: {
              color: '#1C8A9D'
            },
            symbol: 'circle',  // 数据点形状为三角形
            symbolSize: 10,       // 数据点大小为10
            itemStyle: {
              color: '#2DF675'      // 数据点颜色为红色
            },
            data: dustList
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      this.chartInstance.setOption(option);
    },
    // 可选：在组件销毁前销毁echarts实例，避免内存泄漏
    beforeUnmount() {
      if (this.chartInstance) {
        this.chartInstance.dispose();
      }
    }
  }
};
</script>

<style scoped>
/* 你可以在这里添加额外的样式 */
</style>
